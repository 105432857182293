.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-grid-size: 4.5px;
  --ag-list-item-height: 30px;
}

.ag-theme-alpine.compact,
.ag-theme-alpine-dark.compact {
  --ag-grid-size: 4px;
  --ag-list-item-height: 27px;
}