.image-uploader {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px;
}

.image-uploader.hover {
  background-color: #d0d0d0;
}

.image-uploader:hover .uploaded-image {
  filter: brightness(70%);
  /* Darken the image on hover */
}

.image-uploader .uploaded-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s;
}

.image-uploader.hover .uploaded-image {
  filter: brightness(70%);
}

.image-uploader .placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #555;
  font-size: 18px;
  font-weight: bold;
}

.image-uploader .remove-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.image-uploader .btn {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.image-uploader .btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.image-uploader .btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}