/* GLOBAL STYLES
-------------------------------------------------- */
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  background: none;
}

.comment-body {
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  border: top;
}

.avatar-user {
  border-radius: 50% !important;
}

.TimelineItem:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 2px;
  content: "";
  background-color: #e1e4e8;
}

.TimelineItem-avatar {
  position: absolute;
  left: -72px;
  z-index: 1;
}

.timeline-comment-actions {
  float: right;
  margin-left: 8px;
}

.timeline-comment-header {
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  color: #586069;
  flex-direction: row-reverse;
  background-color: #f6f8fa;
  border-bottom: 1px solid #d1d5da;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.comment-body {
  width: 100%;
  padding: 15px;
  overflow: visible;
  font-size: 14px;
}

.no-wrap {
  white-space: nowrap !important;
}

.page-responsive .previewable-comment-form .comment-form-head.tabnav {
  background: #f6f8fa;
}

.previewable-comment-form .comment-form-head.tabnav {
  padding: 8px 8px 0;
  background: #f6f8fa;
  border-radius: 6px 6px 0 0;
}

.timeline-new-comment .comment-form-head {
  margin-bottom: 8px;
}

.previewable-comment-form .tabnav {
  position: relative;
  padding: 8px 8px 0;
}

.tabnav-tab.selected,
.tabnav-tab[aria-current]:not([aria-current="false"]),
.tabnav-tab[aria-selected="true"] {
  background-color: #fff;
  border-color: #e1e4e8;
  border-radius: 6px 6px 0 0;
}

.previewable-edit .previewable-comment-form .form-actions {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.clearfix:after,
.clearfix:before {
  display: table;
  content: "";
}

.previewable-comment-form textarea {
  display: block;
  width: 100%;
  min-height: 100px;
  max-height: 500px;
  padding: 8px;
  resize: vertical;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

.input-contrast {
  background-color: #fafbfc;
}

.form-actions .btn {
  float: right;
}

.clearfix:after {
  clear: both;
}

.clearfix:after,
.clearfix:before {
  display: table;
  content: "";
}

.TimelineItem {
  position: relative;
  display: flex;
  padding: 16px 0;
  margin-left: 16px;
}

.gutter-lg {
  margin-right: -16px;
  margin-left: -16px;
}

.gutter-condensed {
  margin-right: -8px;
  margin-left: -8px;
}

.tabnav-tab {
  display: inline-block;
  flex-shrink: 0;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 23px;
  color: #24292e;
  text-decoration: none;
  background-color: initial;
  border: 1px solid transparent;
  border-bottom: 0;
}

.markdown-body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}

.timeline-comment.current-user .timeline-comment-header {
  background-color: #f1f8ff;
  border-bottom-color: #c0d3eb;
}

.link-gray {
  color: #586069 !important;
}

.avatar {
  display: inline-block;
  overflow: hidden;
  line-height: 1;
  vertical-align: middle;
  border-radius: 6px;
}

.timeline-comment-actions {
  float: right;
  margin-left: 8px;
}

.timeline-comment-header-text {
  min-width: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 1px;
  flex: 1 1 auto;
}

.timeline-comment {
  position: relative;
  background-color: #fff;
  border: 1px solid #d1d5da;
  border-radius: 6px;
}

.timeline-comment {
  position: relative;
  color: #24292e;
  background-color: #fff;
  border: 1px solid #d1d5da;
  border-radius: 6px;
}

.timeline-comment.current-user {
  border-color: #c0d3eb;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: block;
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: none;
}

.timeline-comment--caret.current-user:before {
  border-width: 8px;
  border-right-color: #c0d3eb;
}

.timeline-comment--caret.current-user:after,
.timeline-comment--caret.current-user:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment--caret:before {
  border-width: 8px;
  border-right-color: #d1d5da;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

@media (min-width: 768px) {

  .page-responsive .timeline-comment--caret:after,
  .page-responsive .timeline-comment--caret:before {
    display: block;
  }
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: none;
}

.timeline-comment--caret.current-user:after,
.timeline-comment--caret.current-user:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

@media (min-width: 768px) {

  .page-responsive .timeline-comment--caret:after,
  .page-responsive .timeline-comment--caret:before {
    display: block;
  }
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: none;
}

.timeline-comment--caret.current-user:after,
.timeline-comment--caret.current-user:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

@media (min-width: 768px) {

  .page-responsive .timeline-comment--caret:after,
  .page-responsive .timeline-comment--caret:before {
    display: block;
  }
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: none;
}

.timeline-comment--caret.current-user:after {
  margin-top: 1px;
  margin-left: 2px;
  border-width: 7px;
  border-right-color: #f1f8ff;
}

.timeline-comment--caret.current-user:after,
.timeline-comment--caret.current-user:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment--caret:after {
  margin-top: 1px;
  margin-left: 2px;
  border-width: 7px;
  border-right-color: #f6f8fa;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment.current-user {
  border-color: #c0d3eb;
}

.timeline-comment,
.timeline-comment-header {
  border-color: #e1e4e8;
}

.timeline-comment {
  position: relative;
  /* color: #24292e; */
  background-color: #fff;
  border: 1px solid #d1d5da;
  border-radius: 6px;
}

.ml-n3 {
  margin-left: -16px !important;
}

.TimelineItem-body {
  min-width: 0;
  max-width: 100%;
  margin-top: 4px;
  color: #444d56;
  flex: auto;
}

@media (min-width: 768px) {

  .page-responsive .timeline-comment--caret:after,
  .page-responsive .timeline-comment--caret:before {
    display: block;
  }
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: none;
}

.timeline-comment--caret.current-user:before {
  border-width: 8px;
  border-right-color: #c0d3eb;
}

.timeline-comment--caret.current-user:after,
.timeline-comment--caret.current-user:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment--caret:before {
  border-width: 8px;
  border-right-color: #d1d5da;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

@media (min-width: 768px) {

  .page-responsive .timeline-comment--caret:after,
  .page-responsive .timeline-comment--caret:before {
    display: block;
  }
}

.page-responsive .timeline-comment--caret:after,
.page-responsive .timeline-comment--caret:before {
  display: none;
}

.timeline-comment--caret.current-user:after {
  margin-top: 1px;
  margin-left: 2px;
  border-width: 7px;
  border-right-color: #f1f8ff;
}

.timeline-comment--caret.current-user:after,
.timeline-comment--caret.current-user:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.timeline-comment--caret:after {
  margin-top: 1px;
  margin-left: 2px;
  border-width: 7px;
  border-right-color: #f6f8fa;
}

.timeline-comment--caret:after,
.timeline-comment--caret:before {
  position: absolute;
  top: 11px;
  right: 100%;
  left: -16px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
}

.badge-pill {
  display: inline-block;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  text-align: center;
}

.Box-row {
  padding: 16px;
  margin-top: -1px;
  list-style-type: none;
  border-top: 1px solid #e1e4e8;
}

.link-gray-dark {
  color: #24292e !important;
}

.muted-link {
  color: #586069 !important;
}

.no-underline {
  text-decoration: none !important;
}

.Box-header {
  padding: 16px;
  margin: -1px -1px 0;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0px;
  margin-left: -1px;
  background-color: #f6f8fa;
  border: 1px solid #e1e4e8;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.link-gray-dark:hover {
  color: #0366d6 !important;
}

.IssueLabel {
  display: inline-block;
  padding: 0 7px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border: 1px solid transparent;
  border-radius: 2em;
}

.table-list-header-toggle .btn-link.selected,
.table-list-header-toggle .btn-link.selected:hover {
  color: #24292e;
}

.table-list-header-toggle .btn-link {
  color: #586069;
}

.table-list-header-toggle .btn-link+.btn-link {
  margin-left: 10px;
}

.Box-row:hover {
  background-color: #f6f8fa;
}

.Box--responsive {
  margin-right: 0;
  margin-left: 0;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
}

.Box--responsive .Box-header {
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.muted-link:hover {
  color: #0366d6 !important;
  text-decoration: none;
}

.card:hover .custom-control-input {
  opacity: 0;
}

.pulse {
  transition: all ease 500ms;
}

.issue-title {
  font-weight: 400;
  line-height: 1.125;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.pulse:hover {
  transform: scale(1.03);
  transition: all ease 500ms;
}

.card .container {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.edit-btn:hover {
  font-weight: bold;
  color: #4bb8f2;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.card :hover {
  opacity: 1;
  transition: all 0.1s;
}

[data-theme="dark"] .Box-header {
  background-color: #24292e;
  border: 1px solid #7e7f7f;
}

[data-theme="dark"] .Box-row:hover {
  background-color: #202123;
}

[data-theme="dark"] .link-gray-dark {
  color: #d1e5f9 !important;
}

[data-theme="dark"] .link-gray {
  color: #98a5b5 !important;
}

[data-theme="dark"] .timeline-comment.current-user .timeline-comment-header {
  background-color: #212727;
  border-bottom-color: #2a2e33;
}

[data-theme="dark"] .timeline-comment-header {
  color: #cfe1f5;
}

[data-theme="dark"] .timeline-comment {
  background-color: #000;
  color: #cfe1f5;
  border: 1px solid #424345;
}

[data-theme="dark"] .comment-body {
  color: #cfe1f5;
}

[data-theme="dark"] .Box--responsive {
  border: 1px solid #383a3b;
}