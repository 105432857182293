body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-purple {
  background-color: #6f42c1;
}

.link:hover {
  color: #0054ad;
}

.mouse-pointer {
  cursor: pointer;
}

.scroll-horizontal {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent;
}

.scroll-horizontal::-webkit-scrollbar {
  width: 6px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 3px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: transparent;
}

/* body with a data-theme=dark */
[data-theme='dark'] {
  background-color: #121314;
  color: #fff;
  --bs-body-color: #efe6d3;
  --bs-body-bg: #121314;
  --bs-light-rgb: 27, 30, 31;
  --bs-link-hover-color: #fff;
  --bs-border-color: #3e3f40;
}

[data-theme='dark'] .card {
  --bs-card-bg: #000;
  --bs-card-border-color: rgba(255, 255, 255, 0.375);
}

[data-theme='dark'] .bg-white {
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

[data-theme='dark'] .nav {
  --bs-nav-link-color: #579aff;
}

[data-theme='dark'] .nav-tabs {
  --bs-nav-tabs-link-hover-border-color: #443f3f #443f3f #63636b;
  --bs-nav-tabs-link-active-border-color: #63636b #63636b #000;
  --bs-nav-tabs-border-color: #63636b;
  --bs-nav-tabs-link-active-bg: #000;
  --bs-nav-tabs-link-active-color: #abbbcb;
}

[data-theme='dark'] .table {
  --bs-table-bg: #121314;
}

[data-theme='dark'] .sidebar .nav-link {
  color: #ccc;
}

[data-theme='dark'] .text-muted {
  color: #9e9689 !important;
}

[data-theme='dark'] .form-select {
  background-color: #000000;
  color: #b0c5d9;
  border: 1px solid #3e3f41;
}

[data-theme='dark'] .table-list-header-toggle .btn-link {
  color: #a7b6c7;
}

[data-theme='dark'] .btn-outline-secondary {
  --bs-btn-color: #bfceda;
  --bs-btn-border-color: #a7b4bf;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #51585e;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

[data-theme='dark'] .ck {
  --ck-color-base-foreground: #242424;
  --ck-color-base-background: #000;
  --ck-color-base-border: #ccced1;
  --ck-color-base-action: #53a336;
  --ck-color-base-focus: #6cb5f9;
  --ck-color-base-text: #ccc;
  --ck-color-base-active: #2977ff;
  --ck-color-base-active-focus: #0d65ff;
  --ck-color-base-error: #db3700;
  --ck-color-focus-border-coordinates: 218, 81.8%, 56.9%;
  --ck-color-focus-border: hsl(var(--ck-color-focus-border-coordinates));
  --ck-color-focus-outer-shadow: #cae1fc;
  --ck-color-focus-disabled-shadow: rgba(119, 186, 248, .3);
  --ck-color-focus-error-shadow: rgba(255, 64, 31, .3);
  --ck-color-text: var(--ck-color-base-text);
  --ck-color-shadow-drop: rgba(0, 0, 0, .15);
  --ck-color-shadow-drop-active: rgba(0, 0, 0, .2);
  --ck-color-shadow-inner: rgba(0, 0, 0, .1);
  --ck-color-button-default-background: transparent;
  --ck-color-button-default-hover-background: #2e2e2e;
  --ck-color-button-default-active-background: #2e2e2e;
  --ck-color-button-default-disabled-background: transparent;
  --ck-color-button-on-background: #1b1c1e;
  --ck-color-button-on-hover-background: #33383e;
  --ck-color-button-on-active-background: #2c3238;
  --ck-color-button-on-disabled-background: #686c70;
  --ck-color-button-on-color: #c1c1c1;
  --ck-color-button-action-background: var(--ck-color-base-action);
  --ck-color-button-action-hover-background: #4d9d30;
  --ck-color-button-action-active-background: #4d9d30;
  --ck-color-button-action-disabled-background: #7ec365;
  --ck-color-button-action-text: var(--ck-color-base-background);
  --ck-color-button-save: #008a00;
  --ck-color-button-cancel: #db3700;
  --ck-color-switch-button-off-background: #939393;
  --ck-color-switch-button-off-hover-background: #7d7d7d;
  --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
  --ck-color-switch-button-on-hover-background: #4d9d30;
  --ck-color-switch-button-inner-background: var(--ck-color-base-background);
  --ck-color-switch-button-inner-shadow: rgba(0, 0, 0, .1);
  --ck-color-dropdown-panel-background: var(--ck-color-base-background);
  --ck-color-dropdown-panel-border: var(--ck-color-base-border);
  --ck-color-input-background: var(--ck-color-base-background);
  --ck-color-input-border: var(--ck-color-base-border);
  --ck-color-input-error-border: var(--ck-color-base-error);
  --ck-color-input-text: var(--ck-color-base-text);
  --ck-color-input-disabled-background: #f2f2f2;
  --ck-color-input-disabled-border: var(--ck-color-base-border);
  --ck-color-input-disabled-text: #757575;
  --ck-color-list-background: var(--ck-color-base-background);
  --ck-color-list-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-list-button-on-background: var(--ck-color-button-on-color);
  --ck-color-list-button-on-background-focus: var(--ck-color-button-on-color);
  --ck-color-list-button-on-text: var(--ck-color-base-background);
  --ck-color-panel-background: var(--ck-color-base-background);
  --ck-color-panel-border: var(--ck-color-base-border);
  --ck-color-toolbar-background: var(--ck-color-base-background);
  --ck-color-toolbar-border: var(--ck-color-base-border);
  --ck-color-tooltip-background: var(--ck-color-base-text);
  --ck-color-tooltip-text: var(--ck-color-base-background);
  --ck-color-engine-placeholder-text: #707070;
  --ck-color-upload-bar-background: #6cb5f9;
  --ck-color-link-default: #0000f0;
  --ck-color-link-selected-background: rgba(31, 176, 255, .1);
  --ck-color-link-fake-selection: rgba(31, 176, 255, .3);
  --ck-focus-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-outer-shadow);
  --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-disabled-shadow);
  --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-error-shadow);
  --ck-focus-ring: 1px solid var(--ck-color-focus-border);
}