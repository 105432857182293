.uReVN {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
img {
  border: none;
}

.bVd1Ed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.RyLQS .LmV4df.CtN5Z {
  color: rgba(0, 0, 0, 0.87);
}
.RyLQS .LmV4df {
  color: rgba(0, 0, 0, 0.54);
}
.AwADOd,
.doVMIb {
  text-align: left;
}
.ZdiZXe,
.XgRaPc {
  padding: 0 18px 0 18px;
  text-align: right;
}

.DELv9e .lNsEYb {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 36px;
  max-width: 36px;
  overflow: hidden;
  width: 36px;
}
.YSlEcd {
  margin-right: 16px;
}
.ryDQid {
  -webkit-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 48px;
}

.jAirTd {
  display: none;
}

.RyLQS .LmV4df.CtN5Z {
  color: rgba(0, 0, 0, 0.87);
}
.RyLQS .LmV4df {
  color: rgba(0, 0, 0, 0.54);
}
.doVMIb {
  padding: 0 10px 0 10px;
}
.AwADOd,
.doVMIb {
  text-align: left;
}
.XgRaPc {
  position: relative;
  vertical-align: middle;
  height: 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ZdiZXe,
.XgRaPc {
  padding: 0 18px 0 18px;
  text-align: right;
}

.xPsMrf,
.V4TKmb .xPsMrf,
.OaLLmb.jQIeF .V4TKmb .pLkCzb {
  display: none;
}
.AwADOd,
.doVMIb {
  text-align: left;
}
